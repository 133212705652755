import { Box, Link, Text } from '@chakra-ui/react';

import { Button } from '../button';

type DownloadReportProps = {
  link: string;
};

export const DownloadReport = ({ link }: DownloadReportProps) => {
  return (
    <Box mt={4}>
      <Text color="blueGray.900" size="lg">
        Thanks! Click the button below to download your report.
      </Text>
      <Button height="50px" w="100%" mt={4} href={link} primary as={Link}>
        Download
      </Button>
    </Box>
  );
};
