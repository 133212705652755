import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import type { ReactNode } from 'react';

// NOTE: This is mostly copied from the portal project.
// If we find we need to keep making changes in the future we should put this in a shared package

export const HeadingTheme = {
  baseStyle: {
    fontWeight: 'semibold',
  },
  sizes: {
    sm: {
      fontSize: 'lg',
    },
    md: {
      fontSize: 'xl',
    },
    lg: {
      fontSize: '2xl',
    },
  },
};

export const InputTheme = {
  variants: {
    outline: {
      field: {
        bgColor: 'white',
      },
    },
  },
};

const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontSize: 'sm',
        bgColor: '#F1F1F1',
      },
      '*::placeholder': {
        color: 'blueGray.400',
        opacity: 1,
      },
      'select > option:first-of-type[value=""]': {
        color: 'blueGray.400',
      },
    },
  },
  fonts: {
    body: "'Poppins', sans-serif",
    heading: "'Poppins', sans-serif",
  },
  colors: {
    blueGray: {
      50: '#f8fafc',
      100: '#f1f5f9',
      200: '#e2e8f0',
      300: '#cbd5e1',
      400: '#94a3b8',
      500: '#64748b',
      600: '#475569',
      700: '#334155',
      800: '#1e293b',
      900: '#0f172a',
    },
    blue: {
      50: '#eff6ff',
      100: '#dbeafe',
      200: '#bfdbfe',
      300: '#93c5fd',
      400: '#60a5fa',
      500: '#3b82f6',
      600: '#2563eb',
      700: '#1d4ed8',
      800: '#1e40af',
      900: '#1e3a8a',
    },
    red: {
      50: '#fef2f2',
      100: '#fee2e2',
      200: '#fecaca',
      300: '#fca5a5',
      400: '#f87171',
      500: '#ef4444',
      600: '#dc2626',
      700: '#b91c1c',
      800: '#991b1b',
      900: '#7f1d1d',
    },
  },
  components: {
    Heading: HeadingTheme,
    Input: InputTheme,
  },
  shadows: {
    'base-inverted':
      'rgba(0, 0, 0, 0.1) 0px -1px 3px 0px, rgba(0, 0, 0, 0.06) 0px -1px 2px 0px',
    card: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
  },
});

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};
