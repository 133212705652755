import { Box, Container, Stack } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { NotFound } from './components/not-found';
import { ReportWrapper } from './components/report-wrapper';
import { sentryDsn, sentryEnabled } from './config';

Sentry.init({
  dsn: sentryDsn,
  enabled: sentryEnabled,
  environment: process.env.appEnv,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

export default function LandingPage() {
  return (
    <>
      <Container maxW="3xl">
        <Stack
          as={Box}
          textAlign="center"
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Stack
            direction="column"
            spacing={3}
            align="center"
            alignSelf="center"
            position="relative"
          >
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path=":id">
                <Route path=":key" element={<ReportWrapper />} />
                <Route path="" element={<NotFound />} />
              </Route>
            </Routes>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}
