export enum ReportBranding {
  RACV = 'RACV',
  PROPERTY_SAFE = 'PROPERTY_SAFE',
}

export const REPORT_BRANDING_LABEL: {
  [key in ReportBranding]: string;
} = {
  [ReportBranding.RACV]: 'RACV',
  [ReportBranding.PROPERTY_SAFE]: 'PropertySafe',
} as const;

export const REPORT_BRANDING_ABN: {
  [key in ReportBranding]: string;
} = {
  [ReportBranding.RACV]: 'PropertySafe Holdings Pty Ltd - ABN 28 610 176 504',
  [ReportBranding.PROPERTY_SAFE]:
    'PropertySafe (Aust) Pty Ltd - ABN 32 610 732 619',
} as const;

export const REPORT_BRANDING_LEGAL_NAME: {
  [key in ReportBranding]: string;
} = {
  [ReportBranding.RACV]: 'PropertySafe Holdings Pty Ltd',
  [ReportBranding.PROPERTY_SAFE]: 'PropertySafe (Aust) Pty Ltd',
} as const;

export const REPORT_ENQUIRIES_CONTACT = 'inspections@htha.com.au';
