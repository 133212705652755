import { Box, Center, Spinner, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useGetReportDownloadLink } from '../../graphql/hooks/use-get-report-download-uri';
import { useCaptureError } from '../../hooks/use-capture-error-event';
import { DownloadReport } from '../download-report';
import { PinInputPanel } from '../pin-input';

type DownloadSectionProps = {
  reportKey: string;
  reportId: string;
};

const TEN_MINUTES = 600000;

export const DownloadSection = ({
  reportKey,
  reportId,
}: DownloadSectionProps) => {
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const {
    mutate,
    data: reportLinkData,
    isLoading,
    error: linkError,
    reset,
  } = useGetReportDownloadLink();

  useCaptureError(linkError);

  useEffect(() => {
    const errorMessage = reportLinkData && reportLinkData.message;
    if (errorMessage) {
      setError(errorMessage);
    }
  }, [reportLinkData]);

  useEffect(() => {
    if (reportLinkData?.link) {
      timeoutRef.current = setTimeout(() => {
        setPin('');
        setError('Download expired. Please re-enter PIN');
        reset();
      }, TEN_MINUTES);
    } else {
      clearTimeout(timeoutRef.current);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [reportLinkData?.link, reset]);

  const requestLink = useCallback(() => {
    if (!/^[0-9]{6}$/.test(pin)) {
      setError('Pin must be 6 digits');
      return;
    }
    mutate({ pin, key: reportKey, id: reportId });
  }, [pin, reportKey, reportId, mutate]);

  if (isLoading) {
    return (
      <Center minHeight={100}>
        <Spinner />
      </Center>
    );
  }

  if (reportLinkData?.link) {
    return <DownloadReport link={reportLinkData?.link} />;
  }

  return (
    <Box mt={4}>
      <Text color="blueGray.900" size="lg">
        Please enter your report PIN
      </Text>
      <PinInputPanel
        value={pin}
        error={error}
        onChange={(pin) => {
          setPin(pin);
          setError('');
        }}
        onSubmit={requestLink}
      />
    </Box>
  );
};
