import { Box, Center, Image } from '@chakra-ui/react';
import { ReportBranding } from '@htha/inspections-constants/dist/reporting'; // Import this directly as we don't want to bundle the rest of the constants and expose financial data
import React from 'react';

import propertySafeNSW from '../../assets/propertysafe-nsw.jpg';
import propertySafeQLD from '../../assets/propertysafe-qld.jpg';
import propertySafeVIC from '../../assets/propertysafe-vic.jpg';
import racv from '../../assets/racv.jpg';

const images = {
  [`${ReportBranding.PROPERTY_SAFE}-NSW`]: propertySafeNSW,
  [`${ReportBranding.PROPERTY_SAFE}-QLD`]: propertySafeQLD,
  [`${ReportBranding.PROPERTY_SAFE}-VIC`]: propertySafeVIC,
  [`${ReportBranding.RACV}`]: racv,
};

type ReportImageProps = {
  reportType: string;
};

export const ReportImage = ({ reportType }: ReportImageProps) => {
  const imagePath = images[reportType] || racv;

  return (
    <Center>
      <Center>
        <Box rounded="lg" mt={-10} pos="relative" height="100%" width="100%">
          <Image
            boxShadow="md"
            rounded="lg"
            height={375}
            objectFit="cover"
            src={imagePath}
          />
        </Box>
      </Center>
    </Center>
  );
};
