import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import React from 'react';

export const Card = ({ children, ...rest }: BoxProps) => {
  return (
    <Box
      role="group"
      w="full"
      bg="white"
      boxShadow="sm"
      rounded="lg"
      pos="relative"
      zIndex={1}
      {...rest}
    >
      {children}
    </Box>
  );
};
