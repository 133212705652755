import gql from 'graphql-tag';
import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: string;
};

export type DownloadResponse = {
  __typename?: 'DownloadResponse';
  link?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  getReportLinkURL: DownloadResponse;
};


export type MutationGetReportLinkUrlArgs = {
  input: ReportLinkDownloadQueryArgs;
};

export type Query = {
  __typename?: 'Query';
  getReportLinkDetails?: Maybe<ReportLinkDetails>;
};


export type QueryGetReportLinkDetailsArgs = {
  input: ReportLinkQueryArgs;
};

export type ReportLinkDetails = {
  __typename?: 'ReportLinkDetails';
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  coverImage: Scalars['String'];
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  key: Scalars['String'];
  pinCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ReportLinkDownloadQueryArgs = {
  id: Scalars['String'];
  key: Scalars['String'];
  pin: Scalars['String'];
};

export type ReportLinkQueryArgs = {
  id: Scalars['String'];
  key: Scalars['String'];
};


export const GetReportLinkDetails = gql`
    query getReportLinkDetails($input: ReportLinkQueryArgs!) {
  getReportLinkDetails(input: $input) {
    enabled
    addressLine1
    addressLine2
    coverImage
  }
}
    `;
export const GetDownloadLink = gql`
    mutation getDownloadLink($input: ReportLinkDownloadQueryArgs!) {
  getReportLinkURL(input: $input) {
    message
    link
  }
}
    `;

export const GetReportLinkDetailsDocument = gql`
    query getReportLinkDetails($input: ReportLinkQueryArgs!) {
  getReportLinkDetails(input: $input) {
    enabled
    addressLine1
    addressLine2
    coverImage
  }
}
    `;
export const GetDownloadLinkDocument = gql`
    mutation getDownloadLink($input: ReportLinkDownloadQueryArgs!) {
  getReportLinkURL(input: $input) {
    message
    link
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getReportLinkDetails(variables: GetReportLinkDetailsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReportLinkDetailsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReportLinkDetailsQuery>(GetReportLinkDetailsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReportLinkDetails', 'query');
    },
    getDownloadLink(variables: GetDownloadLinkMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDownloadLinkMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDownloadLinkMutation>(GetDownloadLinkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getDownloadLink', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;
export type GetReportLinkDetailsQueryVariables = Exact<{
  input: ReportLinkQueryArgs;
}>;


export type GetReportLinkDetailsQuery = { __typename?: 'Query', getReportLinkDetails?: { __typename?: 'ReportLinkDetails', enabled: boolean, addressLine1: string, addressLine2: string, coverImage: string } | null };

export type GetDownloadLinkMutationVariables = Exact<{
  input: ReportLinkDownloadQueryArgs;
}>;


export type GetDownloadLinkMutation = { __typename?: 'Mutation', getReportLinkURL: { __typename?: 'DownloadResponse', message?: string | null, link?: string | null } };
