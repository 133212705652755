import { Box, Center, Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react';

type ErrorComponentProps = {
  text?: string;
};

export const ErrorComponent = ({ text }: ErrorComponentProps) => {
  return (
    <Center>
      <VStack>
        <Heading color="red.600">An error has occurred.</Heading>
        {text && (
          <Text fontSize="md" mt={4} color="red.600">
            {text}
          </Text>
        )}
        <Box maxWidth={400}>
          <Text pt={4}>
            For questions or help relating to your property inspection and/or
            report, please email our Inspection team at{' '}
          </Text>
          <Text color="blue.600" fontSize="xs">
            <a href="mailto:inspections@htha.com.au.">
              inspections@htha.com.au
            </a>
          </Text>
        </Box>
      </VStack>
    </Center>
  );
};
