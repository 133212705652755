import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

export const useCaptureError = (error: any) => {
  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);
};

export const useCaptureMessage = (message: string) => {
  useEffect(() => {
    Sentry.captureMessage(message);
  }, [message]);
};
