import { Box, Flex, Heading, Link, Spinner, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { useGetFileLinkDetails } from '../../graphql/hooks/use-get-file-link-details';
import { useCaptureError } from '../../hooks/use-capture-error-event';
import { Card } from '../card';
import { DownloadSection } from '../download-section';
import { ErrorComponent } from '../error-component';
import { NotFound } from '../not-found';
import { ReportImage } from '../report-image';

export const ReportWrapper = () => {
  const { key, id } = useParams();

  const { data, isLoading, isError, error } = useGetFileLinkDetails({
    key,
    id,
  });

  useCaptureError(error);

  if (!id || !key) {
    return <NotFound />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!data || isError) {
    return (
      <Flex flexDir="column">
        <ErrorComponent text="Please check the link has been entered correctly." />
      </Flex>
    );
  }

  return (
    <Flex flexDir="column" maxWidth={367}>
      <Heading fontSize="30px" color="blueGray.900">
        Download your report
      </Heading>
      <Box mt={6}>
        <Text fontWeight={500} color="blueGray.700" fontSize="24px">
          {data?.addressLine1}
        </Text>
        <Text fontWeight={500} color="blueGray.700" fontSize="24px">
          {data?.addressLine2}
        </Text>
      </Box>
      <Box py={16}>
        <Card pb={8}>
          <ReportImage reportType={data.coverImage} />
          <Flex pt={4} flexDir="column" mx={10}>
            <DownloadSection reportKey={key} reportId={id} />
          </Flex>
        </Card>
        <Text fontSize="xs" color="blueGray.500" pt={4}>
          For questions or help relating to your property inspection and/or
          report, please email our Inspection team at{' '}
        </Text>
        <Text color="blue.600" fontSize="xs">
          <Link href="mailto:inspections@htha.com.au.">
            inspections@htha.com.au
          </Link>
        </Text>
      </Box>
    </Flex>
  );
};
