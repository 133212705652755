import { Box, HStack, Input, Text } from '@chakra-ui/react';
import React from 'react';

import { Button } from '../button';

type PinInputPanelProps = {
  onSubmit: (pin: string) => void;
  onChange: (pin: string) => void;
  error?: string | null;
  value: string;
};

export const PinInputPanel = ({
  onSubmit,
  onChange,
  error,
  value,
}: PinInputPanelProps) => {
  return (
    <Box>
      <HStack mt={4}>
        <Input
          onChange={({ target }) => {
            onChange(target.value);
          }}
          value={value}
          maxLength={6}
          type="numeric"
          placeholder="Enter your 6 digit PIN"
        />
        <Button
          onClick={() => {
            onSubmit(value);
          }}
          primary
        >
          Submit
        </Button>
      </HStack>
      {error && (
        <Text mt={4} color="red.600">
          {error}
        </Text>
      )}
    </Box>
  );
};
