import { useMutation } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';

import { gqlUrl } from '../../../config';
import { getSdk } from '../../generated';

type UseGetReportDownloadProps = {
  id?: string;
  key?: string;
  pin?: string;
};

export const useGetReportDownloadLink = () => {
  const client = getSdk(new GraphQLClient(gqlUrl));

  const { data, ...rest } = useMutation(
    ['get-report-download-link'],
    async ({ key, id, pin }: UseGetReportDownloadProps) => {
      if (!key || !id || !pin) {
        return { getReportLinkURL: { message: 'Error' } };
      }

      return client.getDownloadLink({ input: { id, key, pin } });
    }
  );

  return {
    data: data?.getReportLinkURL,
    ...rest,
  };
};
