import { useQuery } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';

import { gqlUrl } from '../../../config';
import { getSdk } from '../../generated';

export const useGetFileLinkDetails = ({
  id,
  key,
}: {
  id?: string;
  key?: string;
}) => {
  const client = getSdk(new GraphQLClient(gqlUrl));

  const { data, ...rest } = useQuery(
    ['get-report-link-details', id, key],
    async () => {
      if (!id || !key) {
        return;
      }

      return client.getReportLinkDetails({ input: { id, key } });
    },
    { enabled: Boolean(id) && Boolean(key) }
  );

  return {
    data: data?.getReportLinkDetails || null,
    ...rest,
  };
};
