import type { ButtonProps as ChakraButtonProps } from '@chakra-ui/react';
import { Button as ChakraButton } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import type { ReactNode } from 'react';

export type ButtonProps = ChakraButtonProps & {
  primary?: boolean;
  secondary?: boolean;
  outlined?: boolean;
  warning?: boolean;
  ghost?: boolean;
  disabled?: boolean;
  children: ReactNode;
  href?: string;
};

export const Button = forwardRef<ButtonProps, 'button'>(
  (
    {
      primary = false,
      secondary = false,
      outlined = false,
      warning = false,
      ghost = false,
      disabled = false,
      children,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const commonProps = {
      size: 'md',
      borderRadius: '3',
      colorScheme: 'blue',
      fontWeight: '500',
      disabled,
      ref,
      ...props,
    };

    if (primary) {
      return (
        <ChakraButton
          variant="solid"
          textColor="white"
          bg="blue.600"
          _hover={{
            textColor: 'white',
            bg: 'blue.700',
          }}
          _pressed={{
            textColor: 'white',
            bg: 'blue.500',
          }}
          _disabled={{
            textColor: 'blueGray.400',
            bg: 'blueGray.300',
          }}
          {...commonProps}
        >
          {children}
        </ChakraButton>
      );
    }

    if (secondary) {
      return (
        <ChakraButton
          variant="solid"
          textColor="blue.600"
          bg="blue.100"
          _hover={{
            textColor: 'blue.600',
            bg: 'blue.200',
          }}
          _pressed={{
            textColor: 'blue.600',
            bg: 'blue.50',
          }}
          {...commonProps}
        >
          {children}
        </ChakraButton>
      );
    }

    if (outlined) {
      return (
        <ChakraButton
          variant="outline"
          textColor="blue.600"
          bg="white"
          _hover={{
            textColor: 'blue.600',
            bg: 'blue.50',
          }}
          _pressed={{
            textColor: 'blue.600',
            bg: 'blue.100',
          }}
          {...commonProps}
        >
          {children}
        </ChakraButton>
      );
    }

    if (warning) {
      return (
        <ChakraButton
          variant="solid"
          textColor="white"
          bg="red.600"
          _hover={{
            textColor: 'white',
            bg: 'red.700',
          }}
          _pressed={{
            textColor: 'white',
            bg: 'red.500',
          }}
          _disabled={{
            textColor: 'blueGray.400',
            bg: 'blueGray.300',
          }}
          {...commonProps}
        >
          {children}
        </ChakraButton>
      );
    }

    if (ghost) {
      return (
        <ChakraButton
          variant="ghost"
          textColor="blue.600"
          _hover={{
            bg: 'blue.50',
          }}
          _pressed={{
            bg: 'blue.100',
          }}
          _disabled={{
            color: 'blueGray.400',
            bg: 'none',
          }}
          {...commonProps}
        >
          {children}
        </ChakraButton>
      );
    }

    return (
      <ChakraButton
        variant="ghost"
        textColor="blueGray.600"
        _hover={{
          textColor: 'blueGray.600',
          bg: 'blueGray.100',
        }}
        _pressed={{
          textColor: 'blue.600',
          bg: 'blue.50',
        }}
        {...commonProps}
      >
        {children}
      </ChakraButton>
    );
  }
);

Button.displayName = 'Button';
