import { Box, Center, Heading, Text, VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { useCaptureMessage } from '../../hooks/use-capture-error-event';

export const NotFound = () => {
  const params = useParams();

  useCaptureMessage(`404 Error with params ${JSON.stringify(params)}`);

  return (
    <Center>
      <VStack>
        <Heading>Oops! You seem to be lost.</Heading>
        <Text fontSize="2xl" mt={4}>
          404 - Page not found
        </Text>
        <Box maxWidth={400}>
          <Text pt={4}>
            For questions or help relating to your property inspection and/or
            report, please email our Inspection team at{' '}
          </Text>
          <Text color="blue.600" fontSize="xs">
            <a href="mailto:inspections@htha.com.au.">
              inspections@htha.com.au
            </a>
          </Text>
        </Box>
      </VStack>
    </Center>
  );
};
